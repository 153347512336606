
import { mapState } from 'vuex'

export default {
  data() {
    return {
      cid: 0
    }
  },
  // mounted() {
  //   let menu = this.defaultMenu
  //   if (this.menu) {
  //     menu = this.menu
  //   }
  //   menu.categories.forEach((category,i) => {
  //     // TODO: select appropriate category (favorites)
  //     if (i == 4) {
  //       category.products.forEach((item,i2) => {
  //         if (i2 < 4) {
  //           this.products.push(item)
  //         }
  //       })
  //     }
  //   })
  // },
  computed: {
    ...mapState(['menu', 'defaultMenu', 'user', 'homepageTopAlertContentCards', 'activeOrder', 'order', 'channel']),
    favorites() {
      let menu = this.defaultMenu
      if (this.menu) {
        menu = this.menu
      }
      let favorites = []
      menu?.categories.forEach((category, i) => {
        // TODO: select appropriate category (favorites)
        if (i == 4) {
          this.cid = category?.id
          category?.products.forEach((item, i2) => {
            if (i2 < 4) {
              favorites.push(item)
            }
          })
        }
      })
      return favorites
    },


    mobile() {
      return (this.$mq == 'sm' || this.$mq == 'md')
    },

    categories() {
      // Check if defaultMenu and menu are valid objects
      const menu = this.menu && typeof this.menu === 'object' ? this.menu : (this.defaultMenu && typeof this.defaultMenu === 'object' ? this.defaultMenu : null);

      // If menu is not valid, return an empty array
      if (!menu || !Array.isArray(menu.categories)) {
        console.error("Menu or categories not available or invalid");
        return [];
      }

      // Use filter to return only categories with available items for the handoff mode
      return menu.categories.filter(category => this.hasAvailableItems(category));
    },

  },
  methods: {
    getCals(product) {
      if (product.calories.base && product.calories.max) {
        return product.calories.base + '-' + product.calories.max + ' Cal'
      } else if (product.calories.base) {
        return product.calories.base + ' Cal'
      } else if (product.calories.max) {
        return product.calories.max + ' Cal'
      }
    },

    slug(name) {
      // console.log(this.channel, 'channel')
      return name.toLowerCase().replace(/ /g, '-').replace(/'/g, '').replace(/’/g, '').trim()
    },

    getImage(category) {
      let image = ''
      // console.log(category, 'category')

      if (category?.images?.length) {  // Verifica se existem imagens
        category.images.forEach(type => {
          if ((type.groupname == 'mobile-webapp-menu' || type.groupname == 'mobile-webapp-customize') && this.mobile) {
            image = type.filename
            // console.log(image, 'image')
          }
          if ((type.groupname == 'desktop-menu' || type.groupname == 'responsive-large') && !this.mobile) {
            image = type.filename
          }
        })

        return process.env.GW_ENV === 'stage' || process.env.GW_ENV === 'prod' 
          ? "https://olo-images-live.imgix.net/" + image 
          : "https://olo-images.imgix.net/" + image
      } else {
        return 'https://a.storyblok.com/f/110020/1180x787/7417b3db74/category-thumb.jpg'
      }
    },


    goTo(category) {
      this.$nextTick(() => {
        if (!this.headerHeight) {
          setTimeout(() => {
            this.goTo(category)
          }, 1000)
          return false
        }
        const section = document.querySelector('[data-category="' + this.slug(category) + '"]')
        if (section) {
          const navHeight = this.mobile ? 48 : 65
          let position = (section.offsetTop - parseInt(this.headerHeight.replace('px', '')) - navHeight)
          // Scroll to top if first category is selected
          if (category == this.categories[0].name.split(' - ')[0]) {
            position = 0
          }
          window.scrollTo({ top: position, behavior: 'smooth' })
        }
      })
    },

    hasAvailableItems(category) {
      if (this.order && this.order.handoff) {
        let hasAvailableItems = false
        category.products.forEach((product) => {
          const unavailablehandoffmodes = product.unavailablehandoffmodes || []
          if (!unavailablehandoffmodes.includes(this.order.handoff)) {
            hasAvailableItems = true
          }
        })
        return hasAvailableItems
      } else {
        return true
      }
    },

    getProductImageSrc(product) {
      if (product && product.images && product.images[1]) {
        const baseUrl =
          process.env.GW_ENV === 'stage' || process.env.GW_ENV === 'prod'
            ? '//olo-images-live.imgix.net/'
            : '//olo-images-sandbox.imgix.net/';
        return baseUrl + product.images[1].filename;
      }
      return '';
    },
  }
}
